let chainID,
    networkID,
    RPCUrl,
    BlockChainExplorerURL,
    NetworkName,
    AQRTokenAddress;

if ( window.location.hostname === "www.aqarchain.io" ) {
    chainID = `0x${Number( 137 ).toString( 16 )}`;
    networkID = 137;
    RPCUrl = "https://nd-233-917-189.p2pify.com/3d99a1869e2e1a754b4a9a66e7fdf95e";
    BlockChainExplorerURL = "https://polygonscan.com/";
    NetworkName = "Polygon";
    AQRTokenAddress = "0xaE204EE82E60829A5850FE291C10bF657AF1CF02";
} else {
    chainID = `0x${Number( 80001 ).toString( 16 )}`;
    networkID = 137;
    RPCUrl = "https://nd-806-729-751.p2pify.com/7b8a2f2e611df7f099c6176f12530402";
    BlockChainExplorerURL = "https://mumbai.polygonscan.com/";
    NetworkName = "Matic TestNet";
    AQRTokenAddress = "0xaE204EE82E60829A5850FE291C10bF657AF1CF02";
}


export {
    chainID,
    networkID,
    RPCUrl,
    BlockChainExplorerURL,
    NetworkName,
    AQRTokenAddress
}