import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = [
  'https://nd-233-917-189.p2pify.com/3d99a1869e2e1a754b4a9a66e7fdf95e',
]

const getNodeUrl = () => {
  return sample( nodes )
}

export default getNodeUrl
