import { useEffect } from 'react';
import { useState } from 'react';
import Web3 from 'web3';
import { OffchainOracle } from '../abi';
import { useWeb3React } from "@web3-react/core";

const useOracle = () => {
    const { active } = useWeb3React();

    const web3 = new Web3( active ? window.ethereum : new Web3.providers.HttpProvider( 'https://nd-233-917-189.p2pify.com/3d99a1869e2e1a754b4a9a66e7fdf95e' ) );
    const OracleContract = new web3.eth.Contract( OffchainOracle.abi, OffchainOracle.networks[1].address ).methods
    const [AQRPrice, setAQRPrice] = useState( 0 );
    const [oneDAI, setOneDAI] = useState( 0 );
    useEffect( async () => {
        const AQR = "0xae204ee82e60829a5850fe291c10bf657af1cf02";
        const DAI = "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063";
        const _AQRPrice = await OracleContract.getRate( AQR, DAI, true ).call();
        const _DAIPrice = await OracleContract.getRate( DAI, AQR, true ).call();
        setAQRPrice( _AQRPrice / ( 10 ** 18 ) );
        setOneDAI( _DAIPrice / ( 10 ** 18 ) );
    }, [] )
    return { AQRPrice, oneDAI }
}

export default useOracle