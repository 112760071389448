import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import getSmartContract from "../../utils/getsmartcontract";
import getInEth from "../../utils/getInEth";
import getTokenSmartContract from "../../utils/getTokenSmartContract";
import { farms } from "../../Config/index";

const StakingCard = ( props ) => {
  const { i, pool } = props;
  const pid = i == "0" || i == "2" ? "0" : "1";

  const [timerValue, setTimerValue] = useState( "-1" );
  const [connectedNetworkId, setNetworkId] = useState( null );
  const [btnLoading, setBtnLoading] = useState( false );
  const [allowanceOfAQR, setAllowanceOfAQR] = useState( "0" );
  const [AQRTokenBalance, setAQRTokenBalance] = useState( "0" );
  const [apy, setApy] = useState( "0.00" );
  const [TotalAqr, setTotalAqr] = useState()
  const [TotalLp, setTotalLp] = useState()

  const [localValues, setLocalValues] = useState( {
    stakedValue: "0",
    nvi: "0",
    accRewards: "0",
  } );

  const { account } = useWeb3React();
  const web3 = new Web3( window.ethereum );

  const loadLocalValues = async () => {
    const networkId = await web3.eth.net.getId();
    setNetworkId( networkId );
    if ( networkId === 137 && account ) {
      try {
        const FarmSmartContract = getSmartContract( i );
        const AQRSmartContract = getTokenSmartContract(
          farms[i].tokenAddresses[137]
        );

        // get local values
        const { amount, lastInteractionAt } = await FarmSmartContract.methods
          .userInfo( pid, account )
          .call();
        const AQRStakedValueInEth = getInEth( amount );

        const AQRAccRewardsInWei = await FarmSmartContract.methods
          .pendingAQR( pid, account )
          .call();
        const AQRAccRewardsInEth = getInEth( AQRAccRewardsInWei );

        let AQRNviInEth;
        if ( AQRAccRewardsInEth && AQRStakedValueInEth ) {
          AQRNviInEth = new BigNumber( AQRAccRewardsInEth )
            .multipliedBy( 100 )
            .div( new BigNumber( AQRStakedValueInEth ) );
        } else AQRNviInEth = new BigNumber( 0 );

        // get allowance of AQR
        const tokenAllowance = await AQRSmartContract.methods
          .allowance( account, farms[i].lpAddresses[137] )
          .call();
        const tokenAllowanceInEth = getInEth( tokenAllowance );

        setAllowanceOfAQR( () => tokenAllowanceInEth );

        // get AQR token balance
        const tokenBalance = await AQRSmartContract.methods
          .balanceOf( account )
          .call();
        const tokenBalanceInEth = getInEth( tokenBalance );

        setAQRTokenBalance( () => tokenBalanceInEth );

        //apy calulations

        const globalTotalAQRtakedInWei = await FarmSmartContract.methods
          .totalAqr()
          .call();

        const globalTotaLPtakedInWei = await FarmSmartContract.methods
          .totalLp()
          .call();

        const globalTotalAqrStakedInEth = getInEth( globalTotalAQRtakedInWei );
        const globalTotalLpStakedInEth = getInEth( globalTotaLPtakedInWei );

        setTotalLp( new BigNumber( parseInt( globalTotaLPtakedInWei ) )
          .dividedBy( new BigNumber( 10 ).pow( '18' ) )
          .toNumber()
          .toFixed( 2 ) )
        setTotalAqr( new BigNumber( parseInt( globalTotalAQRtakedInWei ) )
          .dividedBy( new BigNumber( 10 ).pow( '18' ) )
          .toNumber()
          .toFixed( 2 ) )

        const AQRPerBlock = await FarmSmartContract.methods
          .AQRPerBlock()
          .call();
        const totalAllocPoint = await FarmSmartContract.methods
          .totalAllocPoint()
          .call();
        // bcoz LP = 1 & CNT = 0
        const { allocPoint } = await FarmSmartContract.methods
          .poolInfo( pid )
          .call();
        const globalBlockRewardInWei =
          ( allocPoint / totalAllocPoint ) * AQRPerBlock;

        const globalBlockRewardInEth = getInEth( globalBlockRewardInWei );

        // MATIC = 15017143 & Mainnet = 2340000
        let globalStakingAPYInEth;
        if ( globalTotalAqrStakedInEth !== "0" ) {
          if ( i == "0" || i == "1" ) {
            if ( pid == 0 ) {
              globalStakingAPYInEth = new BigNumber( globalBlockRewardInEth )
                .multipliedBy( 1251428 )
                .div( new BigNumber( globalTotalAqrStakedInEth ) )
                .multipliedBy( 100 );
            } else {
              globalStakingAPYInEth = new BigNumber( globalBlockRewardInEth )
                .multipliedBy( 1251428 )
                .div( new BigNumber( globalTotalLpStakedInEth ) )
                .multipliedBy( 100 );
            }
          } else {
            if ( pid == 0 ) {
              globalStakingAPYInEth = new BigNumber( globalBlockRewardInEth )
                .multipliedBy( 15000000 )
                .div( new BigNumber( globalTotalAqrStakedInEth ) )
                .multipliedBy( 100 );
            }
            else {
              globalStakingAPYInEth = new BigNumber( globalBlockRewardInEth )
                .multipliedBy( 15000000 )
                .div( new BigNumber( globalTotalLpStakedInEth ) )
                .multipliedBy( 100 );
            }
          }
        } else globalStakingAPYInEth = 0;

        setApy( globalStakingAPYInEth.toFixed( 2 ) );

        setLocalValues( {
          stakedValue: AQRStakedValueInEth.toString(),
          nvi: AQRNviInEth.toString(),
          accRewards: AQRAccRewardsInEth.toString(),
        } );
      } catch ( e ) {
        // toastError("Error", "Failed to fetch user details");
      }
    }
  };

  useEffect( () => {
    if ( window.ethereum ) {
      if ( account ) loadLocalValues();
      const init = setInterval( () => {
        if ( account ) loadLocalValues();
      }, 10000 );

      return () => clearInterval( init );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account] );

  return (
    <div className="staking__itme">
      <div className="card">
        <div className="info">
          <img
            src={require( "../../assets/images/staking-pic/info.svg" ).default}
            alt=""
            width="20px"
          />
        </div>
        <div className="card-header">
          <div className="card-img">
            {i == "1" || i == "3" ? (
              <img
                src={
                  require( "../../assets/images/staking-pic/maticaqr02.png" )
                    .default
                }
                alt=""
              />
            ) : (
              <img
                src={
                  require( "../../assets/images/staking-pic/AQR-token.svg" )
                    .default
                }
                alt=""
                style={{ width: "70px" }}
              />
            )}
          </div>
          <h3>STAKE - {farms[i].lpSymbol}</h3>
        </div>
        <div className="card-body">
          <ul className="card-text">
            <li>
              <span>APY :</span>
              <p>{pool.isEnded ? "0" : apy}%</p>
            </li>
            <li>
              <span>Your Stake :</span>
              <p>{Number( localValues.stakedValue ).toFixed( 6 )} {i == "0" || i == "2" ? "AQR" : "LP"}</p>
            </li>
            <li>
              {/* <span>({i == "0" || i == "2"?TotalAqr:TotalLp}{i == "0" || i == "2"?"AQR":"LP"})</span> */}
            </li>
          </ul>
          {
            pool.isEnded ? (
              <Link to={"/pools/" + i} className="btn">
                UNSTAKE
              </Link>
            ) : (
              <Link to={"/pools/" + i} className="btn">
                STAKE NOW
              </Link>
            )
          }
          <p className="durationText">
            staking period {pool.isEnded ? <span style={{
              color: "red",
              fontSize: "14px",
              opacity: "0.6"
            }}>(Expired)</span> : <span>{pool.stakingPeriod}</span>}
          </p>
        </div>
        <div className="info-card">
          <div className="info">
            <img
              src={
                require( "../../assets/images/staking-pic/info-2.svg" ).default
              }
              alt=""
              width="20px"
            />
          </div>
          <div className="info-card__header">
            <div className="info-card-img">
              <img
                src={
                  require( "../../assets/images/staking-pic/writing-notes.svg" )
                    .default
                }
                alt=""
              />
            </div>
          </div>
          <div className="info-card__body">
            <p>Total {i == "0" || i == "2" ? "AQR" : "LP"} Staked</p>
            <h4>{i == "0" || i == "2" ? TotalAqr : TotalLp} {i == "0" || i == "2" ? "AQR" : "LP"}</h4>
          </div>
          <div className="info-card__footer">
            <div className="metamask"></div>
          </div>
        </div>
        <div className="card-footer">
          <ul>
            <li>
              <span>Rewards Earned</span>
              <p>{Number( localValues.accRewards ).toFixed( 2 )} AQR</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StakingCard;
