import React from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { injected } from '../../connectors';
import WalletPopup from '../../components/Popup/Popup';
import WalletDetailsPopup from '../../components/WalletDetailsPopup/WalletDetailsPopup';
import { useEffect, useState } from 'react';
import trimAdd from '../../utils/TrimAddress';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setupNetwork } from '../../utils/wallet';
import Menus from '../../components/Menu';

function Header(props) {
	const location = useLocation();
	const dispatch = useDispatch();
	const { active, account, chainId } = useWeb3React();
	const [walletOpen, setWalletOpen] = useState(false);
	const closeModal = () => setWalletOpen(false);
	const [changePopupOpen, setChangePopupOpen] = useState(false);
	const closeModalChangePopup = () => setChangePopupOpen(false);
	const { token, user } = props;

	// This Switch to polygon is not good. I'll change it ASAP.
	const setNetwork = async () => {
		const web3 = window.ethereum;
		if (web3) {
			await web3.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						chainId: `0x${Number(137).toString(16)}`,
						chainName: 'Matic',
						nativeCurrency: {
							name: 'MATIC',
							symbol: 'MATIC',
							decimals: 18,
						},
						rpcUrls: ['https://nd-233-917-189.p2pify.com/3d99a1869e2e1a754b4a9a66e7fdf95e'],
						blockExplorerUrls: ['https://polygonscan.com/'],
					},
				],
			});
		} else {
			alert("Can't setup the Polygon network on metamask because window.ethereum is undefined");
		}
	};

	return (
		<div>
			{walletOpen && (
				<WalletPopup walletOpen={walletOpen} setWalletOpen={setWalletOpen} closeModal={closeModal} />
			)}
			{changePopupOpen && (
				<WalletDetailsPopup
					setWalletOpen={setWalletOpen}
					changePopupOpen={changePopupOpen}
					setChangePopupOpen={setChangePopupOpen}
					closeModal={closeModalChangePopup}
				/>
			)}
			{/* <HeaderNew /> */}
			<header className={`header `}>
				<div className='container-lg'>
					<nav className='navbar navbar-expand-lg navbar-light' style={{ flexWrap: 'nowrap' }}>
						<a className='navbar-brand' href='https://emiratescoin.pages.dev/'>
							<img
								style={{ opacity: '0' }}
								className='logo-img'
								src={require('../../assets/images/Black.png').default}
								title='Aqarchain'
								alt='Aqarchain'
							/>
						</a>

						<div
							className='collapse navbar-collapse justify-content-xl-center justify-content-around'
							id='navbarNav'
						>
							<Menus />
						</div>
						<div style={{ display: 'flex', gap: '1rem' }}>
							{location.pathname !== '/discover' && location.pathname !== '/' ? (
								<button
									className='btn btn-gradient btn-rounded btn-sm w-100px'
									type='button'
									onClick={
										active
											? () => setChangePopupOpen(true)
											: chainId
											? () => setWalletOpen(true)
											: setNetwork
									}
								>
									{active ? trimAdd(account) : chainId ? 'Connect Wallet' : 'Switch to polygon'}
								</button>
							) : null}
							<Link
								to={token !== '' ? '/dashboard/profile' : '/login'}
								className='btn btn-gradient btn-rounded btn-sm w-100px'
								type='button'
							>
								{token !== '' ? `${!user?.first_name ? 'NA' : user?.first_name}` : 'Login'}
							</Link>
						</div>

						<button
							className='navbar-toggler'
							type='button'
							data-toggle='collapse'
							data-target='#navbarNav'
							aria-controls='navbarNav'
							aria-expanded='false'
							aria-label='Toggle navigation'
						>
							<span className='navbar-toggler-icon' />
						</button>
					</nav>
				</div>
			</header>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(Header);
