import React from 'react';
import { Link } from 'react-router-dom';
import ClaimProperty from './ClaimProperty';
import RealEstate from './RealEstate';
import ShareProperty from './ShareProperty';
import Login from '../../components/Login';

const LoginPage = () => {
	return (
		<>
			<div id='user-registration-section_1-signup-step6' className='container-lg'>
				<div className='row no-gutters'>
					<div className='col-xl-1 col-md-12'>
						<div className='user-registration-card-bg'>
							<img alt='' className='p-4' src='/images/dots-variant-1.svg' />
						</div>
					</div>
					<div className='col-xl-5 col-lg-6 col-md-12 col-12'>
						<div className='py-md-7 has-box-shadow'>
							<div className='form-heading my-5'>
								<h3 className='font-weight-bold text-dark'>Login or Signup to take</h3>
								<h3 className='font-weight-bold text-dark'>
									your Property Listing to a whole new level
								</h3>
							</div>
							<Login />
							<small className='form-text text-muted text-center'>
								Don't have an account?{' '}
								<span className='font-weight-bold text-muted'>
									<Link to={'/registration'}>Click here to register</Link>
								</span>
							</small>
						</div>
					</div>
					<div className='col-lg-6 col-12 text-center text-lg-left'>
						<div className='registration-artwork-top'>
							<h2>
								World's First real estate NFT Marketplace with a{' '}
								<span className='font-weight-bold'>Metaverse</span>
							</h2>
						</div>
						<div className='registration-artwork-bottom'>
							<img alt='' className='img-fluid w-90' src='images/User-Registration-Artwork-2.png' />
						</div>
					</div>
				</div>
			</div>
			{/* <RealEstate />
      <ClaimProperty />
      <ShareProperty /> */}
		</>
	);
};

export default LoginPage;
