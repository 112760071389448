import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import css from './index.module.scss';
import PropertyCard from './PropertyCard';
import TopSection from './TopSection';
import { getTotenizedProperty } from '../../Redux/action/App';
import IsLoadingHOC from '../../components/IsLoadingHOC';

const NewDiscover = ({ setLoading }) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState('upcoming');
	const [properties, setProperties] = useState([]);
	useEffect(() => {
		getProperties();
	}, [open]);

	const getProperties = async () => {
		setLoading(true);
		dispatch(getTotenizedProperty()).then(
			(res) => {
				let property = [];
				res.data.filter((item) => {
					if (item.property.property_status.toLowerCase() === open) {
						property.push(item);
					}
				});
				setProperties(property);
				setLoading(false);
			},
			(err) => {
				console.log(err);
				setLoading(false);
			}
		);
	};

	return (
		<>
			<TopSection open={open} setOpen={setOpen} properties={properties} setProperties={setProperties} />
			<section>
				<div className='container-lg'>
					<div className={css.cardGroup}>
						{properties.map((property, index) => (
							<PropertyCard property={property} key={index} open={open} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default IsLoadingHOC(NewDiscover);
