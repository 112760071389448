import { useWeb3React } from '@web3-react/core';
import css from './index.module.scss';
import trimAdd from '../../../utils/TrimAddress';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToWhiteList } from '../../../Redux/action/App';
import { toast } from 'react-toastify';

const WhiteListFrom = ({ id, setLoading, property }) => {
	const { totalTokenSupply } = property;
	const dispatch = useDispatch();
	const { account, chainId } = useWeb3React();
	const [isChecked, setIsChecked] = useState(false);
	const [share, setShare] = useState(1);
	const [isComingSoon, setIsComingSoon] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState('0xc2132D05D31c914a87C6611C10748AEb04B58e8F');
	useEffect(() => {}, [account, isChecked, isComingSoon, share]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (isChecked && account && Number(totalTokenSupply.$numberDecimal) >= share) {
			setLoading(true);
			const data = {
				propertyId: id,
				address: account,
				paymentMethod,
			};
			dispatch(
				addToWhiteList({
					...data,
					share: share,
				})
			).then(
				(res) => {
					setLoading(false);
					toast.success(res.message);
				},
				(err) => {
					setLoading(false);
					if (Array.isArray(err.response.data.message)) {
						err.response.data.message.map((message) => {
							toast.error(message);
						});
					}
					toast.error(err.response.data.message);
				}
			);
		}
	};

	const setNetwork = async () => {
		const web3 = window.ethereum;
		if (web3) {
			await web3.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID).toString(16)}`,
						chainName: 'Matic',
						nativeCurrency: {
							name: 'MATIC',
							symbol: 'MATIC',
							decimals: 18,
						},
						rpcUrls: [`${process.env.REACT_APP_POLYCON_RPC_URL}`],
						blockExplorerUrls: [`${process.env.REACT_APP_POLYCON_EXP_URL}`],
					},
				],
			});
		} else {
			alert("Can't setup the Polygon network on metamask because window.ethereum is undefined");
		}
	};

	const paymentMethods = [
		{
			title: 'USDT',
			value: 'USDT',
		},
		{
			title: 'USDC',
			value: 'USDC',
		},
		// {
		// 	title: 'AQR',
		// 	value: 'AQR',
		// },
		// {
		//     title: "GlobePay",
		//     value: "globe pay",
		//     comingSoon: true
		// }
	];

	return (
		<>
			<h3 className={css.title}>Get Allocation of Share</h3>
			<form action='' className={css.from} onSubmit={submitHandler}>
				<ul className={css.dataList}>
					<li>
						Wallet Address
						<div className={css.wallet}>
							{chainId === Number(process.env.REACT_APP_CHAIN_ID) ? (
								<span className={css.balance}></span>
							) : (
								<button type='button' className={css.switch} onClick={setNetwork}>
									<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
										<path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 9h2v4h2V9h2l-3-3.5L7 9zm10 6h-2v-4h-2v4h-2l3 3.5 3-3.5z' />
									</svg>
								</button>
							)}
							{
								<span
									className={`${css.account} ${
										chainId === Number(process.env.REACT_APP_CHAIN_ID) ? '' : css.worng
									}`}
								>
									{trimAdd(account)}
								</span>
							}
						</div>
					</li>
					<li>
						Allocation of Shares
						<input
							type='number'
							defaultValue={share}
							onChange={(e) => {
								setShare(e.target.value);
							}}
						/>
					</li>
					<li>
						Preferred payment
						<select
							className={css.select}
							name='shares'
							id='shares'
							onChange={(e) => {
								const value = e.target.value.split(',');
								setIsComingSoon(value[1] === 'true' ? true : false);
								setPaymentMethod(value[0]);
							}}
						>
							{paymentMethods.map((method, index) => (
								<option key={index} value={`${method.value},${method.comingSoon}`}>
									{method.title}
								</option>
							))}
						</select>
						{isComingSoon && <span className={css.comingSoon}>Coming Soon</span>}
					</li>
				</ul>
				<div className={css.checkbox}>
					<input type='checkbox' id='accepttc' onChange={(e) => setIsChecked(e.target.checked)} />
					<label htmlFor='accepttc'>
						<span>
							Accept <a href='#'>terms and conditions</a>
						</span>
					</label>
				</div>
				{!account ? (
					<div className={css.warning}>Please connect your wallet</div>
				) : (
					<button
						type='submit'
						disabled={Number(totalTokenSupply.$numberDecimal) >= share ? !isChecked : isChecked}
					>
						Submit
					</button>
				)}
			</form>
		</>
	);
};

export default WhiteListFrom;
