import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import factoryabi from '../../../abi/aqarfactory.json';
import vaultabi from '../../../abi/aqarvault.json';
import AQAR from '../../../abi/aqrbalance.json';
import Multicall from '../../../helper/multicall/multicall';
import { factory, mapToken } from '../../../constants/addresses.json';
import { useDispatch } from 'react-redux';
import { getTotenizedProperty } from '../../../Redux/action/App';
import { useWeb3React } from '@web3-react/core';

const Portfolio = () => {
	const [propblockdata, setpropblockdata] = useState([]);
	const [allProperty, setAllProperty] = useState([]);
	const [aqrbalance, setaqrbalance] = useState(0);

	const dispatch = useDispatch();
	const { account, chainId } = useWeb3React();

	//getting each user balance
	const gettingPropDataFromBlockchain = async (tokens) => {
		const promises = tokens.map(async (token) => {
			const factorycalls = [
				{
					address: factory.address,
					name: 'propIdTopropTokenId',
					params: [token.property.id],
				},
			];
			const [a] = await Multicall(factoryabi.abi, factorycalls);
			console.log('factorycalls', parseInt(a));

			const vaultaddrescall = [
				{
					address: factory.address,
					name: 'NftToToken',
					params: [parseInt(a)],
				},
			];

			const [b] = await Multicall(factoryabi.abi, vaultaddrescall);
			console.log(account, 'port');
			if (account) {
				const vaultcalls = [
					{
						address: b.toString(),
						name: 'claimableBalance',
						params: [account],
					},
					{
						address: b.toString(),
						name: 'tokenPrice',
					},
					{
						address: b.toString(),
						name: 'name',
					},
					{
						address: b.toString(),
						name: 'symbol',
					},
				];
				const [claimable, tokenPrice, name, symbol] = await Multicall(vaultabi.abi, vaultcalls);
				console.log(
					new BigNumber(parseInt(claimable)).dividedBy(new BigNumber(10).pow('18')).toNumber().toFixed(2)
				);
				var total = [
					new BigNumber(parseInt(claimable)).dividedBy(new BigNumber(10).pow('18')).toNumber().toFixed(3),
					new BigNumber(parseInt(tokenPrice)).dividedBy(new BigNumber(10).pow('18')).toNumber().toFixed(2),
					name,
					symbol,
				];
				return total;
			}
		});

		const resp = await Promise.all(promises);
		setpropblockdata(resp);
	};

	const getuserbalance = async () => {
		if (account) {
			const mapcalls = [
				{
					address: mapToken.address,
					name: 'claimableBalanceSeed',
					params: [account],
				},
				{
					address: mapToken.address,
					name: 'claimableBalancePrivate',
					params: [account],
				},
			];
			const [seed, priv] = await Multicall(AQAR.abi, mapcalls);
			var amount = new BigNumber(parseInt(seed)).dividedBy(new BigNumber(10).pow('18')).toNumber().toFixed(2);
			var amount2 = new BigNumber(parseInt(priv)).dividedBy(new BigNumber(10).pow('18')).toNumber().toFixed(2);
			setaqrbalance(Number(amount) + Number(amount2));
		}
	};

	const loadProperty = async () => {
		const body = {
			limit: '1000',
			filter: {
				propertyTitle: '',
			},
		};
		await dispatch(getTotenizedProperty(body))
			.then(
				(response) => {
					setAllProperty(response.data);
					gettingPropDataFromBlockchain(response.data);
				},
				(error) => {
					console.log('response', error);
				}
			)
			.catch((error) => {
				console.log('response', error);
			});
	};

	useEffect(() => {
		loadProperty();
		getuserbalance();
	}, [account]);

	return (
		<div
			className='tab-pane fade active show'
			id='user-dashboard-nav-tab-my-portfolio'
			role='tabpanel'
			aria-labelledby='user-dashboard-nav-tab-my-portfolio-tab'
		>
			<div className='text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius'>
				<div className='row d-lg-flex align-items-center'>
					<div className='col-lg-4 col-12'>
						<h3 className='font-weight-bold mb-3 mb-lg-4'>My Portfolio</h3>
					</div>
				</div>
				<div className='dashboard-table-container table-responsive'>
					<table className='dashboard-table'>
						<thead>
							<tr>
								<th>Token Name</th>
								<th>Token Code</th>
								<th>Token Price</th>
								<th>Tokens</th>
								<th>Investment Value</th>
							</tr>
						</thead>
						<tbody>
							{allProperty.length !== 0
								? allProperty.map((key, index) =>
										propblockdata.length !== 0 &&
										propblockdata[index] &&
										propblockdata[index][0] > 0 ? (
											<tr>
												<td>
													{propblockdata.length !== 0 &&
														propblockdata[index] &&
														propblockdata[index][2]}
												</td>
												<td>
													{propblockdata.length !== 0 &&
														propblockdata[index] &&
														propblockdata[index][3]}
												</td>
												<td>
													{propblockdata.length !== 0 &&
														propblockdata[index] &&
														propblockdata[index][1]}
												</td>
												<td>
													{propblockdata.length !== 0 &&
														propblockdata[index] &&
														propblockdata[index][0]}
												</td>
												<td>
													{propblockdata.length !== 0 &&
														propblockdata[index] &&
														(propblockdata[index][1] * propblockdata[index][0]).toFixed(2)}
												</td>
											</tr>
										) : null
								  )
								: null}
							<tr>
								<td>Dusit Princess Rijas</td>
								<td>DPR01</td>
								<td>
									{(381.47).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
								<td>10</td>
								<td>
									{(381.47 * 10).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
							</tr>
							<tr>
								<td>Private Infinity Pool 1BR Apartment</td>
								<td>PV510</td>
								<td>
									{(323).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
								<td>2</td>
								<td>
									{(323 * 2).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
							</tr>
							<tr>
								<td>Penthouse at Dubai Marina</td>
								<td>PH3402</td>
								<td>
									{(864).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
								<td>6</td>
								<td>
									{(864 * 6).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
							</tr>
							<tr>
								<td>Pool View Studio Apartment</td>
								<td>PV402</td>
								<td>
									{(382).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
								<td>16</td>
								<td>
									{(382 * 17).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
							</tr>
							<tr>
								<td>Kensington Waters - I at Meydan - 1 BR</td>
								<td>KWI506</td>
								<td>
									{(468).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
								<td>4</td>
								<td>
									{(468 * 4).toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Portfolio;
