import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import Web3 from "web3";
import { AqarMarketplace } from "../../abi";
import { Link, Redirect, useHistory } from "react-router-dom";
import { addToCart } from "../../Redux/action/Cart";
import { useWeb3React } from "@web3-react/core";
import ModelViewer from "../../containers/Aqarland/Marketplace/ModelViewer";

const NFTCard = ( { item, index } ) => {
    const history = useHistory()
    const [count, setCount] = useState( 1 );
    const dispatch = useDispatch();
    const { assetId, nftAddress } = item;
    const [stoke, setStoke] = useState( 0 )
    const [isInCart, setIsInCart] = useState( false )
    const { active } = useWeb3React();
    const RPCProvider = new Web3.providers.HttpProvider( 'https://nd-233-917-189.p2pify.com/3d99a1869e2e1a754b4a9a66e7fdf95e' )
    const web3 = new Web3( active ? window.ethereum : RPCProvider );

    useEffect( async () => {
        const AqarMarketplaceContract = new web3.eth.Contract( AqarMarketplace.abi, AqarMarketplace.networks[137].address ).methods;
        const _stoke = await AqarMarketplaceContract.listings( nftAddress, assetId ).call();
        setStoke( Number( _stoke.quantity ) )
    }, [nftAddress, assetId, count, stoke, isInCart] )

    function generateRandomColor () {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for ( var i = 0; i < 6; i++ ) {
            color += letters[Math.floor( Math.random() * 16 )];
        }
        return color;
    }
    return (
        <div className="assets-card" key={index}>
            {item.nftAddress === "0xa6565fB64de81208582c6be0d0beb61f249701Fc" ?
                (
                    <div className="assets-img">
                        <ModelViewer
                            modal={String( item.nft.animation_url ).replace( "https://aqarchain.mypinata.cloud/", "https://ipfs.io/" )}
                            poster={String( item.nft.image ).replace( "https://aqarchain.mypinata.cloud", "https://ipfs.io/" )} />
                    </div>
                ) : (
                    <div className="assets-img" style={{ backgroundColor: `${generateRandomColor()}` }}>
                        <div onClick={() => {
                            history.push( `/nft/${item.nftAddress}/${item.assetId}` )
                        }}>
                            <img src={String( item.nft.image ).replace( "https://aqarchain.mypinata.cloud", "https://ipfs.io/" )} alt={item.nft.name} />
                        </div>
                    </div>
                )
            }

            <div className="assets-card--body">
                <div className="assets-name">
                    <h4 onClick={() => {
                        history.push( `/nft/${item.nftAddress}/${item.assetId}` )
                    }}>{item.nft.name}</h4>
                    <div>
                        <span>Available : {stoke}</span>
                        <div className="icon price" style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem",
                        }}>
                            <img src={require( "../../assets/images/aqr-icon.svg" ).default} alt="" />
                            <span>{item.oneDAI && item.price ? ( item.price * item.oneDAI ).toLocaleString( "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) : ""}</span>
                        </div>
                    </div>
                </div>
                <div className="assets-btn">
                    <div className="buyBtn-group">
                        <button
                            onClick={() => {
                                setCount( count === 1 ? 1 : count - 1 )
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M5 11h14v2H5z" /></svg>
                        </button>
                        <span>{count}</span>
                        <button
                            onClick={() => {
                                setCount( count === stoke ? stoke : count + 1 )
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" /></svg>
                        </button>
                    </div>
                    <button
                        className={`buyBtn ${item.isInCart ? "active" : isInCart ? "active" : ""}`}
                        onClick={() => {
                            dispatch( addToCart( { ...item, amount: count, availableStoke: stoke } ) )
                            setIsInCart( true )
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM5.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NFTCard