import { Link } from 'react-router-dom';

const Footer = () => {
	return '';
	return (
		<footer className='footer aqarchain-2-footer text-md-left text-center '>
			<div className='container-lg'>
				<div className='aqarchain-2-foote-row-1 row no-gutters pt-5 pb-4'>
					<div className='col-lg-3 col-md-12 col-12 text-lg-left text-center'>
						<a href='https://aqarchain.io/'>
							<img
								className='nav-brand'
								src={require('../../assets/images/Black.png').default}
								alt='aqarchain'
								width='140px'
							/>
						</a>
					</div>
					<div className='col-lg-6 col-md-12 col-12 mt-2 mb-2 text-lg-left text-center'>
						<div className='row'>
							<div className='col-lg-4 col-12'>
								<div className='footer-menu-nav'>
									<p className='nav-item'>
										<a
											href='https://aqarchain.oss-me-east-1.aliyuncs.com/one-pager/Aqarchain-One-Pager-English.pdf'
											className='footer-link cursor-pointer text-dark big'
											target='_blank'
										>
											One Pager
										</a>
									</p>
									<p className='nav-item'>
										<a
											href='https://aqarchain.oss-me-east-1.aliyuncs.com/white-paper/White-Paper-English.pdf'
											target='_blank'
											className='footer-link cursor-pointer text-dark big'
										>
											White Paper
										</a>
									</p>
									<p className='nav-item'>
										<a
											href='https://aqarchain.oss-me-east-1.aliyuncs.com/token-page/Aqarchain-Token-Page-English.pdf'
											className='footer-link cursor-pointer text-dark big'
											target='_blank'
										>
											Token Page
										</a>
									</p>
									<p className='nav-item'>
										<a
											href='https://aqarchain.oss-me-east-1.aliyuncs.com/audit-report/Aqarchain-Smart-Contract-Audit-Report.pdf'
											className='footer-link cursor-pointer text-dark big'
											target='_blank'
										>
											Audit Report
										</a>
									</p>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='footer-menu-nav'>
									<p className='nav-item'>
										<a
											href='https://aqarchain.oss-me-east-1.aliyuncs.com/pitch-deck/Aqarchain-Presentation-English.pdf'
											target='_blank'
											className='footer-link cursor-pointer text-dark big'
										>
											Presentation
										</a>
									</p>
									<p className='nav-item'>
										<Link to='/advisor' className='footer-link cursor-pointer text-dark big'>
											Advisors
										</Link>
									</p>
									<p className='nav-item'>
										<Link to='/team' className='footer-link cursor-pointer text-dark big'>
											Team
										</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-3 col-md-12 col-12 mt-2 mb-2 text-center'>
						<div className='d-inline-block text-lg-left text-center'>
							<ul className='nav social-nav flex-row'>
								<li className='nav-item'>
									<a href='https://www.facebook.com/aqarchain.io' target='_blank'>
										<i className='fab fa-2x text-dark fa-facebook-f' />
									</a>
								</li>
								<li className='nav-item'>
									<a href='https://twitter.com/aqarchain_io' target='_blank'>
										<i className='fab fa-2x text-dark fa-twitter' />
									</a>
								</li>
								<li className='nav-item'>
									<a href='https://www.linkedin.com/company/aqar-chain/mycompany/' target='_blank'>
										<i className='fab fa-2x text-dark fa-linkedin-in' />
									</a>
								</li>
								<li className='nav-item'>
									<a href='https://www.instagram.com/aqarchain/' target='_blank'>
										<i className='fab fa-2x text-dark fa-instagram' />
									</a>
								</li>
								<li className='nav-item'>
									<a href='https://smartchain.medium.com/'>
										<i className='fab fa-2x text-dark fa-medium' />
									</a>
								</li>
								<li className='nav-item '>
									<a href='https://t.me/aqarchainglobal' target='_blank'>
										<i className='fab fa-2x text-dark fa-telegram-plane' />
									</a>
								</li>
								<li className='nav-item'>
									<a href='https://www.youtube.com/channel/UC_-Z2uSuYXWWezpr6mVTtOQ' target='_blank'>
										<i className='fab fa-2x text-dark fa-youtube' />
									</a>
								</li>
								<li className='nav-item '>
									<a href='https://discord.gg/f2wp7zU4Zv' target='_blank'>
										<i className='fab fa-2x text-dark fa-discord' />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='aqarchain-2-foote-row-2 row no-gutters pt-4'>
					<div className='col-lg-12 text-center mt-2 mb-4'>
						<p className='copyright big mb-1 font-normal'>
							Aqarchain.io is currently operational in beta version, we appreciate your feedback and
							support through our telegram channel.
						</p>
						<p className='copyright big mb-1 font-normal'>
							Copyright©2021 aqarchain.io, All rights reserved Smart Chain Holding Corporation.
						</p>
						<p className='copyright big mb-1 font-normal'>
							Company No: 2075222 Intershore Chambers, Road Town, Tortola, British Virgin Islands
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
